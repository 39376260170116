<template>
  <div class="container mt-9">
    <md-card
      v-for="notification in activityLog"
      :key="notification.id"
      class="mb-3 notification-data"
    >
      <md-card-content class="card-style">
        <v-row align="center">
          <v-col cols="7" class="notification-msg">{{
            notification.data && notification.data.message
          }}</v-col>
          <v-col class="md-caption date-style">{{
            new Date(notification.updated_at).toLocaleString()
          }}</v-col>
          <v-col class="view-btn-container"
            ><md-button :href="'/' + notification.data && notification.data.url" class="view-btn"
              >View</md-button
            ></v-col
          >
        </v-row>
      </md-card-content>
    </md-card>
    <md-button v-if="currentPage !== lastPage" @click="fetchMore" class="view-btn"
      >Load More</md-button
    >
  </div>
</template>

<script>
import restAdapter from "../../restAdapter";
export default {
  name: "ActivityLog",
  data: () => ({
    activityLog: [],
    currentPage: 1,
    lastPage: null,
  }),
  async mounted() {
    const response = await restAdapter.get("/api/view_activity_log");
    const data = response.data.activityLog;
    this.activityLog = response.data.activityLog.data;
    this.lastPage = data.last_page;
  },
  methods: {
    async fetchMore() {
      const response = await restAdapter.get(
        `/api/view_activity_log?page=${this.currentPage + 1}`
      );
      this.activityLog.push(...response.data.activityLog.data);
      this.currentPage++;
    },
  },
};
</script>

<style lang="scss" scoped>

.notification-data{
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
}

.card-style{
  padding-bottom: 0px;
  margin-bottom: 28px;
}

.notification-msg{
  text-align: left;
  margin-left: 35px;
  font-weight: 600;
  font-size: 16px;
  line-height: 116.1%;
  color: #442D65;
}

.date-style{
  font-weight: 400;
  font-size: 16px;
  line-height: 116.1%;
  color: #442D65;
}

.view-btn-container{
  text-align: right;
}

.view-btn{
  font-weight: 400;
  font-size: 16px;
  line-height: 116.1%;
  color: #442D65;
}

</style>
